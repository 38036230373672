<template>
    <layout-empty v-if="(loading && isOnline)" @loaded="() => loading = false"/>
    <component v-else :is="layout">
        <router-view/>
    </component>
    <modal-install-app :opened="installModal.opened && !loading" @accepted="installModal.accepted"/>
</template>
<script setup>
import {computed, ref, watch, onMounted, onUnmounted, reactive, nextTick} from "vue";
import {useRouter} from "vue-router";
import {useOnline} from '@vueuse/core'
import LayoutEmpty from "~/layout/LayoutEmpty.vue";
import LayoutNoHeader from "~/layout/LayoutNoHeader.vue";
import Layout from "~/layout/Layout.vue";
import ModalInstallApp from "~/components/ModalConfirmation/ModalInstallApp.vue";
import {DateTimeAdapter} from "~/core/adapters/index.js";
import {useUserInfo} from "~/composables/useUserInfo.js";

let installPrompt = null;
const ASK_INSTALL_PWA_NEXT_DAY = 'ask-install-pwa';

const defaultLayout = "default";
const {replace, currentRoute} = useRouter();
const loading = ref(true)
const isOnline = useOnline()
const {hasUserNotAuthorized} = useUserInfo()
const hasToAskAgain = computed({
    get: () => DateTimeAdapter.create(localStorage.getItem(ASK_INSTALL_PWA_NEXT_DAY)),
    set: (value) => {
        localStorage.setItem(
            ASK_INSTALL_PWA_NEXT_DAY,
            value
        );
    }
})
const installModal = reactive({
    opened: false,
    accepted: (accepted) => {
        if (accepted) {
            installPrompt.prompt();
            installPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome !== 'accepted') {
                    hasToAskAgain.value = DateTimeAdapter.create().addDays(1).toFormatAtom();
                }
                nextTick(() => {
                    window.removeEventListener('beforeinstallprompt', onInstallPwa);
                    installModal.opened = false
                })
            });
        } else {
            hasToAskAgain.value = DateTimeAdapter.create().addDays(1).toFormatAtom();
            installPrompt = null;
            installModal.opened = false;
        }
    }
})

const layout = computed(
    () => {
        const layout = currentRoute.value.meta.layout || defaultLayout
        switch (layout) {
            case 'no-header':
                return LayoutNoHeader
            case 'empty':
                return LayoutEmpty
            default:
                return Layout
        }
    }
)

watch(
    currentRoute,
    (value, oldValue) => {
        if (value.name !== oldValue.name) {
            document.body.style.overflow = 'auto'
        }
    }
)

watch(
    isOnline,
    (value, oldValue) => {
        if (!value) {
            replace({
                name: 'offline'
            })
        }
    },
    {
        immediate: true
    }
)

function onInstallPwa(event) {
    if (import.meta.env.VITE_ENV === 'production' && !hasToAskAgain.value.afterNow()) {
        event.preventDefault();
        installPrompt = event;
        installModal.opened = true;
    }
}



function onAppInstalled() {
    mixpanel.track('app_de_encontros_instalado', {
        origem: "App de Encontros",
    });
}
onMounted(
    () => {
        window.addEventListener('beforeinstallprompt', onInstallPwa);
        window.addEventListener("appinstalled", onAppInstalled);
        window.addEventListener("appnoinstalled", onAppInstalled)
    }
)

onUnmounted(
    () => {
        window.removeEventListener('beforeinstallprompt', onInstallPwa);
        window.removeEventListener("appinstalled", onAppInstalled);
    }
)

</script>
<style lang="scss">
#app {
    position: relative;
    height: 100vh;
}
body {
    overscroll-behavior-y: contain;
}
</style>
